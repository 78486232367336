<template>
    <div>
        <NuxtLoadingIndicator />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>

</template>
<script lang="ts" setup>
import "vanilla-cookieconsent/dist/cookieconsent.css"
import * as CookieConsent from "vanilla-cookieconsent"

const { t } = useI18n();
const { $i18n } = useNuxtApp()

const config = computed(() => {
    return {
        guiOptions: {
            consentModal: {
                layout: "box inline",
                position: "bottom right",
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: "box",
                position: "right",
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        categories: {
            necessary: {
                readOnly: true
            },
            analytics: {}
        },
        language: {
            default: "en",
            translations: {
                en: {
                    consentModal: {
                        description: t('COOKIE_DESCRIPTION', { policy: `<a href="${t('SLUG.PRIVACY_POLICY')}">${t('PRIVACY_POLICY')}</a>`, cookie: `<a href="${t('SLUG.TERMS_OF_USE')}">${t('TERMS_AND_CONDITIONS')}</a>` }),
                        acceptAllBtn: t('ACCEPT_ALL'),
                        acceptNecessaryBtn: t('REJECT_ALL'),
                    },
                }
            }
        }
    }
})

onMounted(() => {
    if (Object.keys(CookieConsent.getCookie()).length === 0) {
        CookieConsent.reset(true);
        CookieConsent.run(config.value);
    }
})

$i18n.onLanguageSwitched = (oldL, newL) => {
    if (Object.keys(CookieConsent.getCookie()).length === 0) {
        CookieConsent.reset(true);
        CookieConsent.run(config.value);
    }
}
</script>
<style>
html {
    scroll-behavior: smooth;
    /* set scroll-behaviour to smooth here */
}
</style>
