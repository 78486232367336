import {useConfigStore} from "~/stores/config";

export const useSeoParse = (seoDetail) => {
    const app = useState("app_config")
    const appConfigStore = useConfigStore();
    const seoGeneral =  app.value.data.seo_urls.find((x: any) => x.router_name === 'general')
    let seoGeneralDetail = seoGeneral.translations.find((x: any) => x.languages_code === appConfigStore.currentLanguage.code)

    seoDetail = {
        ...seoGeneralDetail?.seo_detail,
        ...seoDetail,
    }

    useHead({
        title: seoDetail.meta_title,
        ogTitle: seoDetail.meta_title,
        description: seoDetail.meta_description,
        ogDescription: seoDetail.meta_description,
        ogImage: seoDetail.image_social,
        type: 'website',

        link: [
            {
                rel: 'icon',
                type: 'image/svg+xml',
                href: '/favicon.ico'
            }
        ]
    })
}
