<template>
  <div class="">
    <hero
      :data="dataHome?.first_section"
      :link-button="dataHome?.link_button"
    />
    <results
      :data="dataHome?.second_section"
      :link-button="dataHome?.link_button"
    />
    <profit
      :data="dataHome?.third_section"
      :link-button="dataHome?.link_button"
    />
    <why-skylight
      :data="dataHome?.forth_section"
      :link-button="dataHome?.link_button"
    />
    <pricing
      :data="dataHome?.fifth_section"
      :link-button="dataHome?.link_button"
      :data-pricing="dataPricing"
    />
    <how-it-works
      :data="dataHome?.sixth_section"
      :link-button="dataHome?.link_button"
    />
    <testimonials
      :data="dataHome?.seventh_section"
      :link-button="dataHome?.link_button"
    />
    <faq
      :data="dataHome?.eighth_section"
      :link-button="dataHome?.link_button"
    />
  </div>
</template>

<script setup lang="ts">
import Hero from "./components/hero.vue";
import Results from "./components/results.vue";
import Profit from "./components/profit.vue";
import WhySkylight from "./components/why-skylight.vue";
import Pricing from "./components/pricing.vue";
import HowItWorks from "./components/how-it-works.vue";
import Testimonials from "./components/testimonials.vue";
import Faq from "./components/faq.vue";

import { useAdminClient } from "~/layers/directus/composables/adminClient/useAdminClient";
import { readItems } from "@directus/sdk";
import { useSeoParse } from "~/app/composables/seo/useSeoParse";

const client = await useAdminClient();

const { currentLanguage } = useConfigStore();

const dataHome = ref();
const dataPricing = ref();

const getDataHomePage = () => {
  return client.request(
    readItems("pages/home", {
      lang: currentLanguage?.code,
    })
  );
};

const getPricing = () => {
  return client.request(
    readItems("pricing", {
      lang: currentLanguage?.code,
    })
  );
};

const fetchData = async () => {
  try {
    const [homeResponse, pricingResponse] = await Promise.all([
      getDataHomePage(),
      getPricing(),
    ]);

    dataHome.value = homeResponse;
    console.log(`output->dataHome.value`, dataHome.value);
    dataPricing.value = pricingResponse?.pricing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

await fetchData();

const checkLocationPage = useCheckLocationPage();
onMounted(() => {
  checkLocationPage.handleSetLocationPage("home");
});

useSeoParse(dataHome.value?.seo_detail);
</script>
