import { default as defaultL8jXzGfOOFMeta } from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/pages/default.vue?macro=true";
import { default as indexT969FyuSLBMeta } from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/home/index.vue?macro=true";
import { default as indexBv63SyqOF6Meta } from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/page/index.vue?macro=true";
import { default as component_45stubOTD82Qi9IdMeta } from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOTD82Qi9Id } from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "default___en",
    path: "/default",
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/pages/default.vue")
  },
  {
    name: "default___vi",
    path: "/vi/default",
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/pages/default.vue")
  },
  {
    name: "home___en",
    path: "/",
    alias: ["/vi"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/home/index.vue")
  },
  {
    name: "home___vi",
    path: "/vi",
    alias: ["/vi"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/home/index.vue")
  },
  {
    name: "page___en",
    path: "/page/terms-of-use",
    alias: ["/vi/trang/dieu-khoan-su-dung"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/page/index.vue")
  },
  {
    name: "page___vi",
    path: "/vi/page/terms-of-use",
    alias: ["/vi/trang/dieu-khoan-su-dung"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/page/index.vue")
  },
  {
    name: "page___en",
    path: "/page/privacy-policy",
    alias: ["/vi/trang/chinh-sach-bao-mat"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/page/index.vue")
  },
  {
    name: "page___vi",
    path: "/vi/page/privacy-policy",
    alias: ["/vi/trang/chinh-sach-bao-mat"],
    component: () => import("/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/views/page/index.vue")
  },
  {
    name: component_45stubOTD82Qi9IdMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubOTD82Qi9Id
  }
]