<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none">
    <path
      d="M13.3633 29.8801L23.1433 20.1001C24.2983 18.9451 24.2983 17.0551 23.1433 15.9001L13.3633 6.12012"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style></style>
