<template>
    <div id="faq" class="section py-10 lg:py-16 bg-white relative">
        <div class="container">
            <h2 class="text-[#1A195B] text-7.5 lg:text-10 font-sf-pro font-bold !leading-[1.2] mb-9 text-center">
                {{ data?.title }}
            </h2>
            <div class="max-w-225 mx-auto flex flex-col gap-6 lg:gap-8">
                <div @click="handleShowContent(index)" v-for="(item, index) in data?.list_faq"
                    class="border-b border-solid border-secondary-200 pb-4 flex cursor-pointer">
                    <div class="min-w-6 w-6 h-6 rounded-full bg-primary-500 mt-0 mr-2.75 relative">
                        <div :class="{ 'transform rotate-90': showContent?.includes(index) }"
                            class="w-3 h-0.5 bg-white rounded-full absolute left-1/2 top-1/2 transform -translate-1/2 duration-200 ease">
                        </div>
                        <div class="w-3 h-0.5 bg-white rounded-full absolute left-1/2 top-1/2 transform -translate-1/2">
                        </div>
                    </div>
                    <div class="">
                        <h3 class="title-texts text-5 text-gradient-2 leading-[1.2] font-bold lg:font-extrabold" :class="{ 'show': showContent?.includes(index) }">
                            {{ item?.title }}
                        </h3>
                        <div v-html="item.content" class="content-texts text-sm lg:leading-[1.4]" :class="{ 'show': showContent?.includes(index) }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden sm:flex justify-center mt-9 hidden">
                <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank" class="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    data: Object,
    linkButton: String
})

const showContent = ref([0])
const handleShowContent = (index: any) => {
    if (showContent.value.includes(index)) {
        showContent.value.splice(showContent.value.indexOf(index), 1)
    } else {
        showContent.value.push(index)
    }
}

const { t } = useI18n();
</script>
<style scoped>
.content-texts {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;
    color: #1A195B;
}

.content-texts.show {
    opacity: 1;
    visibility: visible;
    max-height: 200px;
    margin-top: 12px;
}
@media (max-width: 1024px) {
    .content-texts.show {
        margin-top: 8px
    }
}
</style>
