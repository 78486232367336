import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppButton, LazyAppImage, LazyAppParagraph, LazyAppSection, LazyIconsArrowDown, LazyIconsArrowRightLight, LazyIconsGlobal, LazyIconsMenuBar, LazyIconsX, LazyLayoutFooter, LazyLayoutHeader, LazySkeleton, LazyTheBreadcrumbs, LazyWebsiteThePagination, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AppButton", LazyAppButton],
["AppImage", LazyAppImage],
["AppParagraph", LazyAppParagraph],
["AppSection", LazyAppSection],
["IconsArrowDown", LazyIconsArrowDown],
["IconsArrowRightLight", LazyIconsArrowRightLight],
["IconsGlobal", LazyIconsGlobal],
["IconsMenuBar", LazyIconsMenuBar],
["IconsX", LazyIconsX],
["LayoutFooter", LazyLayoutFooter],
["LayoutHeader", LazyLayoutHeader],
["Skeleton", LazySkeleton],
["TheBreadcrumbs", LazyTheBreadcrumbs],
["WebsiteThePagination", LazyWebsiteThePagination],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
