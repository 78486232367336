<template>
    <div id="why-skylight" class="section py-16 pt-10 lg:py-16 relative">
        <app-image :id="data?.background" class="w-full h-full absolute inset-0 object-cover z-1" width="3000"
            height="2000" :alt="data?.title" />
        <div class="container relative z-2">
            <h2 class="text-7.5 lg:text-10 font-sf-pro text-white font-bold leading-[1.5] text-center mb-3 lg:mb-0">
                {{ data?.title }}
            </h2>
            <div v-html="data?.description"
                class="text-base text-white !leading-[1.5] lg:!leading-[2.75] text-center mb-4 lg:mb-9"></div>
            <div class="grid grid-cols-5">
                <div class="col-span-2 hidden lg:block mt-32">
                    <div class="ml-auto relative p-5 pl-10.5 pt-7"
                        :style="`width: ${data?.list_info[0]?.width || '100%'};`">
                        <img class="absolute inset-0 w-full" src="/images/line.svg" alt="" />
                        <h3 class="text-gradient-1 text-xl font-bold !leading-[1.2] tracking-[-1px] mb-6">
                            {{ data?.list_info[0]?.title }}
                        </h3>
                        <div v-html="data?.list_info[0]?.description"
                            class="text-sm leading-normal max-w-90 text-white"></div>
                    </div>
                    <div class="ml-auto mt-10 relative p-5" :style="`width: ${data?.list_info[2]?.width || '100%'};`">
                        <img class="absolute inset-0 w-full" src="/images/line.svg" alt="" />
                        <h3 class="text-gradient-1 text-xl font-bold !leading-[1.2] tracking-[-1px] mb-6">
                            {{ data?.list_info[2]?.title }}
                        </h3>
                        <div v-html="data?.list_info[2]?.description"
                            class="text-sm leading-normal max-w-57 text-white"></div>
                    </div>
                </div>
                <div class="col-span-5 lg:col-span-1">
                    <div class="max-w-full sm:max-w-56 lg:max-w-unset mx-auto lg:mx-unset">
                        <div class="hidden lg:block relative">
                            <app-image :id="data?.image" class="w-full object-contain z-1" width="350" height="650"
                                :alt="data?.title" />
                            <app-image :id="data?.decor_1" :alt="data?.title + ' decoration'"
                                class="w-18 lg:w-29.25 object-contain absolute -top-4 -left-24 z-2" width="350"
                                height="650" />
                            <app-image :id="data?.decor_2"
                                class="w-23.75 object-contain absolute bottom-8 -right-18 z-2" width="350" height="650"
                                :alt="data?.title + ' decoration'" />
                        </div>
                        <div class="flex justify-center">
                            <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank"
                                class="lg:mt-9 !hidden sm:!block" />
                        </div>
                    </div>
                    <div class="grid lg:hidden sm:grid-cols-2 gap-4 mt-6">
                        <div v-for="item in data?.list_info" class="">
                            <div class="h-0.5 w-full bg-gradient-2 mb-4"></div>
                            <h3 class="text-xl font-bold tracking-[-1px] mb-4 text-gradient-1">
                                {{ item?.title }}
                            </h3>
                            <div v-html="item?.description" class="text-base text-white !leading-[1.5]"></div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 hidden lg:block mt-28">
                    <div class="relative pl-20 p-5 xl:pl-10 <xl:!w-full"
                        :style="`width: ${data?.list_info[1]?.width || '100%'};`">
                        <img class="absolute inset-0 w-full" src="/images/line-2.svg" alt="" />
                        <h3 class="text-gradient-1 text-xl font-bold !leading-[1.2] tracking-[-1px] mb-6">
                            {{ data?.list_info[1]?.title }}
                        </h3>
                        <div v-html="data?.list_info[1]?.description"
                            class="text-sm leading-normal max-w-full text-white"></div>
                    </div>
                    <div class="mt-4 relative p-5 pl-10.5 pt-7 pr-7"
                        :style="`width: ${data?.list_info[3]?.width || '100%'};`">
                        <img class="absolute inset-0 w-full" src="/images/line-2.svg" alt="" />
                        <div class="flex items-end flex-col">
                            <h3 class="text-gradient-1 text-xl font-bold !leading-[1.2] tracking-[-1px] mb-6">
                                {{ data?.list_info[3]?.title }}
                            </h3>
                            <div v-html="data?.list_info[3]?.description"
                                class="text-sm leading-normal max-w-65 text-white"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    data: Object,
    linkButton: String,
});

const { t } = useI18n();
</script>
