<template>
    <img :src="urlImage" :alt="alt"/>
</template>
<script setup lang="ts">
import {useAdminClientUrl} from "@directusLayer/composables/adminClient/useAdminClientUrl";
const props = defineProps({
	id: {
		type: String,
		required: true,
        default: null
	},
    alt: {
		type: String,
		required: true,
        default: 'image'
	},
    width: {
		type: String,
		required: true,
	},
    height: {
		type: String,
		required: true,
	},
});


const {
        asset_url,
        url: apiUrl
    } = useAdminClientUrl()

const urlImage = computed(() => {
    if(!props?.id) {
        return '/images/no-image.jpg'
    }
    return `${asset_url}/${props?.id}?w=${props?.width}&h=${props?.height}`
})


</script>