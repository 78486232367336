import {i18n} from "~/config";

let dataConfig: {
    data: Object,
    timer: number
} = {
    data: null,
    timer: 0
}

export default defineNuxtPlugin(async (nuxtApp) => {
    const app = useState("app_config", () => (dataConfig))
    const {
        $i18n
    } = useNuxtApp();

    const {
        translations
    } = app.value.data

    if (translations) {
        Object.keys(translations).forEach((key) => {
            $i18n.setLocaleMessage(key, {
                ...translations[key],
                ...$i18n.getLocaleMessage(key)
            })
        })
    }

    return {}
})
