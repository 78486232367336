<template>
    <div id="product" class="section pt-8 pb-16 lg:pt-34.5 lg:pb-35.75 relative">
        <app-image :id="data?.background" class="w-full h-full absolute inset-0 object-cover z-1" width="3000"
            height="2000" :alt="data?.title" />
        <div class="container relative z-2">
            <div class="grid md:grid-cols-2 gap-10 lg:gap-14">
                <div class="">
                    <h1
                        class="text-12 xl:text-16 font-sf-pro font-extrabold text-center md:text-left !leading-[1.25] md:!leading-[1.08] text-white tracking-[0.72px] max-w-90 sm:max-w-unset mx-auto sm:mx-unset lg:max-w-8/10 mb-6">
                        {{ data?.title }}
                    </h1>
                    <div v-html="data?.description"
                        class="text-4.5 xl:text-5 leading-normal text-center md:text-left tracking-[0.18px] md:tracking-[0.22px] text-white sm:mb-8 md:mb-9">
                    </div>
                    <div class="hidden sm:flex md:inline-block justify-center">
                        <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank" class="" />
                    </div>
                </div>
                <div class="hidden sm:block">
                    <app-image :id="data?.hero_image" class="w-full" width="1100" height="750" :alt="data?.title" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { vIntersectionObserver } from '@vueuse/components'

const props = defineProps({
    data: Object,
    linkButton: String
})

const isStickyButton = ref(false)
const checkStickyButton = ([{ isIntersecting }]) => {
    isStickyButton.value = isIntersecting
}

const { t } = useI18n();
</script>
<style scoped>
.sticky-button {
    position: sticky;
    bottom: 20px;
    left: 0;
}
</style>
