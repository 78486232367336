import routerOptions0 from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}