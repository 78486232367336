import {customEndpoint} from "@directus/sdk";
import { register } from 'swiper/element/bundle';

let dataConfig: {
    data: Object,
    timer: number
} = {
    data: null,
    timer: 0
}

export default defineNuxtPlugin(async (nuxtApp) => {
    const app = useState("app_config", () => (dataConfig))

    if (!app.value.timer) {
        app.value.timer = new Date().getTime()
    }

    if (!app.value.data || (app.value.timer < new Date().getTime() - 1000 * 10)) { // 1 hour
        app.value.timer = new Date().getTime();

        let {
            $adminClient,
        }: any = useNuxtApp()

        app.value.data = await $adminClient.request(customEndpoint({
            method: "GET",
            path: "config"
        }))

        dataConfig = app.value
    }

    //For swiper js
    register();

    return {}
})
