<template>
    <div class="w-full py-25 flex justify-center items-center relative bg-primary-400">
        <app-image class="z-1 absolute inset-0 w-full h-full object-cover object-bottom" id="b15ec233-a6a2-4a9f-a3f3-aa3e7dde8c26"
            alt="banner" width="2000" height="800" />
        <h1
            class="z-2 text-white px-6 text-center text-12 <xl:text-10 <lg:text-8 <md:text-6 <sm:text-5 !leading-[1.6] font-bold leading-[1.3] tracking-[0.2px] uppercase text-shadow-01">
            {{ dataPageStatic?.title }}
        </h1>
    </div>
    <div class="max-w-200 px-3.75 mx-auto pt-10 pb-10 lg:mt-11 lg:pb-20">
        <div v-html="dataPageStatic?.content" class="post-content wysiwyg"></div>
    </div>
</template>

<script setup lang="ts">
import { useAdminClient } from "~/layers/directus/composables/adminClient/useAdminClient";
import { readItems } from "@directus/sdk";

const client = useAdminClient();
const { currentLanguage } = useConfigStore();

const route = useRoute()
const dataPageStatic = ref({})
const getDataPageStatic = async () => {
    const response = await client.request(
        readItems(`pages/static/${route.params?.slug}`, {
            lang: currentLanguage?.code,
        })
    )
    dataPageStatic.value = response

}
await getDataPageStatic()
const checkLocationPage = useCheckLocationPage();
onMounted(() => {
    checkLocationPage.handleSetLocationPage('page')
})

useSeoParse(dataPageStatic.value?.seo_detail)
</script>

<style>
.post-content h1,
.post-content .heading-size-1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;
    color: #020202;
    margin: 16px 0;
    @media (max-width: 639px) {
        font-size: 26px;
    }
}

.post-content h2,
.post-content .heading-size-2 {
    font-size: 24px;
    line-height: 1.8rem;
    margin: 16px 0;
    color: #020202;
    font-weight: 700;
    @media (max-width: 639px) {
        font-size: 22px;
    }
}

.post-content h3,
.post-content .heading-size-3 {
    font-size: 20px;
    line-height: 1.6rem;
    font-weight: 700;
    margin: 16px 0;
    color: #020202;
    @media (max-width: 639px) {
        font-size: 16px;
    }
}

.post-content h4,
.post-content .heading-size-4 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: #020202;
}

.post-content h5,
.post-content .heading-size-5 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.post-content h6,
.post-content .heading-size-6 {
    font-size: 1.2rem;
    letter-spacing: 0.03125em;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.post-content img {
    margin: 16px 0;
    width: 100%;
    height: auto;
}
.post-content em,
.post-content p {
    margin-bottom: 1.2rem;
    text-align: justify;
    font-size: 16px;
    @media (max-width: 639px) {
        font-size: 14px;
    }
}

.post-content em {
    @apply text-base;
}

.post-content p > strong,
.post-content a > strong,
.post-content li > strong {
    @apply text-primary;
}
.post-content a,
.post-content a > strong {
    @apply underline;
}
.post-content p:last-child {
    margin-bottom: 0;
}

.post-content blockquote {
    margin: 0;
    @apply text-primary;
}

.post-content .single h2 {
    font-size: 2rem;
}

.post-content .single h2,
.post-content .single h3,
.post-content .single h4,
.post-content .single h5 {
    font-weight: bold;
    margin-bottom: 30px;
}

.post-content ul {
    padding-left: 0 !important;
    list-style-type: none;
}

.post-content ul li {
    padding-left: 0 !important;
    margin-bottom: 12px;
    margin-left: 16px;
    position: relative;
    @apply text-black/60 text-15px;
}

.post-content ul li:before {
    content: '\25CF';
    color: #020202;
    position: absolute;
    margin-right: 16px;
    margin-bottom: 3px;
    vertical-align: inherit;
    font-size: 0.5rem;
    line-height: 0;
    left: -16px;
    top: 12px;
}

.post-content ul ul {
    list-style: circle;
}

.post-content ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 16px;
    @apply space-y-3;
}

.post-content ol li {
    display: list-item;
    text-align: -webkit-match-parent;
    @apply text-blacks-70 text-15px;
}

.post-content a {
    /* Your custom styles for links */
}

.post-content table {
    margin-bottom: 16px;
    width: 100% !important;
}

.post-content table img {
    width: 100% !important;
    height: 100% !important;
}

.post-content table th,
.post-content table td {
    padding: 6px;
    vertical-align: top;
}

.post-content figure {
    max-width: 100%;
    margin-bottom: 16px;
}

.post-content figcaption p {
    text-align: center;
}

.post-content > figure.alignnone {
    margin-left: 0;
    margin-right: 0;
}

.post-content > figure.alignleft {
    margin-left: 0;
    margin-right: auto;
}

.post-content > figure.alignright {
    margin-left: auto;
    margin-right: 0;
}

/* .overlay-banner:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.4;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
} */
</style>
