<template>
    <div id="pricing" class="section py-10 lg:py-16 bg-primary-400">
        <div class="container">
            <div class="flex flex-col items-center">
                <h2 class="text-white text-7.5 lg:text-10 font-sf-pro text-center font-bold !leading-[1.2] mb-3 lg:mb-0">
                    {{ data?.title }}
                </h2>
                <div v-html="data?.description"
                    class="text-white text-base text-center !leading-normal mb-4">
                </div>
                <div class="p-1 h-12 rounded-6 bg-white w-87.5 flex justify-between mx-auto mb-3">
                    <div class="w-1/2 text-center py-2 px-4 tab-month rounded-6 cursor-pointer text-secondary-400"
                        :class="currentActiveMonth === indexMonth ? 'bg-primary-500 text-white' : ''"
                        v-for="(month, indexMonth) in listMonths" @click="handleTabMonths(indexMonth)">
                        {{ month }} {{ t('MONTHS') }}
                    </div>
                </div>
                <div class="flex gap-1 text-secondary-700 text-sm !leading-5 tracking-[-0.28px] mb-6 lg:mb-9"
                    :class="currentActiveMonth !== 0 ? 'opacity-100 visible' : 'opacity-0 invisible'">
                    <client-only>
                        <div class="">{{ t('SAVE_UP_TO', { percent: currentPercent }) }}</div>
                        <span class="text-white">{{ t('ON_A_PLAN') }}</span>
                    </client-only>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-12 w-full">
                    <div v-for="(item, index) in dataPricing"
                        :class="index === dataPricing?.length - 1 ? 'md:col-span-2 lg:col-span-1 max-w-full md:max-w-[calc((100%-32px)/2)] lg:max-w-unset mx-auto' : ''"
                        class="p-6.5 w-full bg-white rounded-2 relative h-auto !flex flex-col justify-between box-shadow-2">
                        <div class="flex flex-col">
                            <div class="flex items-center justify-between mb-1">
                                <h2 class="text-secondary-500 text-2xl font-bold leading-[1.5] tracking-[-0.72px]">
                                    {{ item?.translations?.title }}
                                </h2>
                                <div v-if="index === 1"
                                    class="py-1.5 px-3 rounded-6.5 bg-success-100 text-success-500 text-sm font-bold !leading-[1.42] tracking-[0.28px]">
                                    {{ t('MOST_POPULAR') }}</div>
                            </div>
                            <div v-if="item?.translations?.content"
                                class="text-secondary-400 text-4.5 font-bold leading-normal tracking-[-0.36px]">
                                {{ item?.translations?.content }}
                            </div>
                            <div class="flex items-center justify-center mt-3">
                                <div
                                    class="text-secondary-500 text-9 font-extrabold leading-[1.94] tracking-[-1.382px] mr-1.5">
                                    $
                                </div>
                                <div
                                    class="text-secondary-500 text-13 font-extrabold leading-[1.16] tracking-[-1.382px]">
                                    {{ getPrice(item?.price_list) }}
                                </div>
                            </div>
                            <div
                                class="mt-1 mb-4 flex justify-center gap-1 text-secondary-400 text-base !leading-[1.5] tracking-[-0.4px]">
                                ${{ pricePerMonth(item?.price_list, 1) }}/month
                            </div>
                            <div
                                class="mb-4 text-4.5 text-primary-300 font-bold leading-normal tracking-[-0.32px] text-center">
                                {{ item?.translations?.intro }}
                            </div>
                            <app-button :link="linkButton" target="_blank" :title="t('SELECT_PLAN')"
                                class="mb-4 !py-3 !font-bold" />
                            <div class="flex flex-col gap-3">
                                <div v-for="option in item?.translations?.options"
                                    class="text-secondary-400 text-sm !leading-5">
                                    <div v-html="option.text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { readItems } from "@directus/sdk";
import { useAdminClient } from "~/layers/directus/composables/adminClient/useAdminClient";

const props = defineProps({
    data: Object,
    linkButton: String,
    dataPricing: Array
})

const client = await useAdminClient();
const { currentLanguage } = useConfigStore();

const currentActiveMonth = ref(1)
const listMonths = ref([6, 12])
const handleTabMonths = (index: number) => {
    currentActiveMonth.value = index
    listDiscounts.value = props.dataPricing?.map(item => calcDiscountPercentage((item.price_list[0]?.price / item.price_list[0]?.duration), (item.price_list[1]?.price / item.price_list[1]?.duration)))
    currentPercent.value = Math.max(...listDiscounts.value)
}

const pricePerMonth = (listPrice: any, quantityNumber: number) => {
    let price
    price = listPrice[currentActiveMonth.value].price / listPrice[currentActiveMonth.value].duration
    if (price % 1 !== 0) {
        return parseFloat(price.toFixed(quantityNumber));
    }
    return Number(price)
}

const getPrice = (priceList: any) => {
    let price = priceList.find(item => item.duration === getDuration(currentActiveMonth.value))
    return price.price
}

const currentPercent = ref(0)
const calcDiscountPercentage = (originalPrice: any, discountedPrice: any) => {
    let discount = originalPrice - discountedPrice;
    let discountPercentage = (discount / originalPrice) * 100;
    let formattedDiscount = parseFloat(discountPercentage.toFixed(2));
    return formattedDiscount;
}

const listDiscounts = ref(props.dataPricing?.map(item => calcDiscountPercentage((item.price_list[0]?.price / item.price_list[0]?.duration), (item.price_list[1]?.price / item.price_list[1]?.duration))))

currentPercent.value = Math.max(...listDiscounts.value)

const getDuration = (duration: number) => {
    let month
    if (duration === 0) {
        month = 6
    } else if (duration === 1) {
        month = 12
    }
    return month
}

const { t } = useI18n();
</script>
<style scoped></style>
