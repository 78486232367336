import { defineStore } from "pinia";

export const useCheckLocationPage = defineStore("useCheckLocationPage", () => {
    const isLocationPage = ref('');
    const isSlug = ref({})
  
    const handleSetLocationPage = (namePage:any, slug: any) => {
      isLocationPage.value = namePage
      isSlug.value = slug
    };
    const handleGetLocationPage = () => {
      return isLocationPage.value
    };
    const handleGetSlug = () => {
      return isSlug.value
    }
  
    return {
      isLocationPage,
      handleSetLocationPage,
      handleGetLocationPage,
      handleGetSlug
    };
  });