<template>
    <div id="our-results" class="section py-10 lg:py-16 relative">
        <app-image :id="data?.background" class="w-full h-full absolute inset-0 object-cover z-1" width="3000"
            height="1200" :alt="data?.title" />
        <div class="container relative z-2">
            <h2
                class="text-7.5 lg:text-10 font-sf-pro text-secondary-600 font-bold leading-normal text-center mb-3 lg:mb-0">
                {{ data?.title }}</h2>
            <div v-html="data?.description"
                class="text-white text-base !leading-[1.5] lg:!leading-[2.75] text-center mb-9"></div>
            <div class="relative">
                <div class="grid grid-cols-2 gap-5 md:gap-0 md:grid-cols-4">
                    <div v-for="(item, index) in data?.list_result"
                        class="p-4 md:p-6 rounded-4 md:rounded-0 bg-[rgba(255,255,255,0.06)] element md:bg-transparent border border-solid border-secondary-50 md:border-none">
                        <!-- <div class="hidden md:block decor-results relative w-full h-[66px] vertical-line-gradient"
                            :class="`decor-results-${index + 1}`">
                        </div> -->
                        <div class="element-inner">
                            <div class="flex gap-2 justify-center items-center md:items-center">
                                <h3
                                    class="text-gradient-1 text-9 md:text-10 lg:text-16 font-extrabold !leading-[1.6] tracking-[-1px]">
                                    {{ item?.title }}
                                </h3>
                                <h3 v-if="item?.title_suffix"
                                    class="text-gradient-1 text-6 lg:text-10 font-extrabold leading-[1.06] tracking-[-1px]">
                                    {{ item?.title_suffix }}
                                </h3>
                                <h3 v-if="item?.subtitle"
                                    :class="index === 3 ? 'max-w-13 lg:max-w-16' : 'max-w-29 lg:max-w-35.5'"
                                    class="text-gradient-1 text-4 md:text-5 lg:text-6 font-bold leading-[1] tracking-[-1px]">
                                    {{ item?.subtitle }}
                                </h3>
                            </div>
                            <div
                                class="mt-3 text-white text-[13px] sm:text-base lg:text-4.5 leading-normal whitespace-nowrap text-center">
                                {{ item?.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden sm:flex justify-center mt-9">
                <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank" class="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    data: Object,
    linkButton: String
})

const { t } = useI18n();
</script>
<style scoped>
.decor-results-1 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.decor-results-2 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.decor-results-3 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.decor-results-4 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.element {
    position: relative;
    background: linear-gradient(to bottom, #554DE9, #1A195B);
    padding: 1px;
    border-radius: 16px;
}

.element-inner {
    background: #1C1B64;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 24px;
}
</style>
