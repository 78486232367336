import validate from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/directus/middleware/auth.global.ts";
import language_45resolve_45global from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/base/middleware/language.resolve.global.ts";
import manifest_45route_45rule from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  language_45resolve_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}