<template>
    <div id="testimonials" class="section py-10 lg:py-16 bg-primary-400 relative">
        <app-image :id="data?.background" class="w-full h-full absolute inset-0 object-cover z-1" width="3000"
            height="2000" :alt="data?.title" />
        <div class="container z-2 relative">
            <h2 class="text-white text-7.5 lg:text-10 font-sf-pro font-bold !leading-[1.2] mb-6 lg:mb-9 text-center">
                {{ data?.title }}
            </h2>
        </div>
        <div class="z-2 pl-4 md:pl-0">
            <Swiper :modules="[SwiperAutoplay, SwiperNavigation, SwiperPagination]" :pagination="true"
                :centered-slides="centeredSlide" :navigation="{
                    nextEl: '.next-swiper-testimonials',
                    prevEl: '.prev-swiper-testimonials',
                }" :loop="true" :breakpoints="{
            0: { slidesPerView: 1.1, spaceBetween: 12 },
            768: { slidesPerView: 1.2, spaceBetween: 12 },
            1200: { slidesPerView: 1.4, spaceBetween: 96 },
            1600: { slidesPerView: 1.8, spaceBetween: 96 },
            2000: { slidesPerView: 2, spaceBetween: 96 },
        }" class="custom-swiper !pb-9">
                <SwiperSlide v-for="(item, index) in data?.list_testimonials" :key="item?.id"
                    class="p-4 md:py-12.5 md:px-7.5 rounded-6 bg-[#171651] opacity-35 border-2px border-solid border-[#4E47D9] h-auto">
                    <div
                        class="absolute w-full left-0 top-50% transform -translate-y-1/2 z-10 hidden md:flex justify-between">
                        <div
                            class="prev-swiper-testimonials pointer-events-none translate-x-8 cursor-pointer w-11.25 h-11.25 flex justify-center items-center bg-white rounded-full transition-all duration-200 ease group hover:scale-105">
                            <icons-arrow-down class="transform rotate-90 text-primary-600" />
                        </div>
                        <div
                            class="next-swiper-testimonials pointer-events-none transform -translate-x-8 cursor-pointer w-11.25 h-11.25 flex justify-center items-center bg-white rounded-full transition-all duration-200 ease group hover:scale-105">
                            <icons-arrow-down class="transform rotate-270 text-primary-600" />
                        </div>
                    </div>
                    <div class="md:px-17.25 md:max-w-273 mx-auto flex flex-col md:items-center">
                        <div v-html="item?.content"
                            class="text-sm md:text-base leading-[1.71] md:!leading-[2] text-white md:text-center mb-5">
                        </div>
                        <div class="flex md:justify-center items-center gap-3.25">
                            <div class="min-w-10 w-10 h-10 md:w-23.25 md:min-w-23.25 md:h-23.25">
                                <app-image :id="item?.avatar" class="w-full h-full object-cover rounded-full" width="150"
                                    height="150" :alt="`${item?.name} - ${item?.content}`" />
                            </div>
                            <div class="">
                                <div class="text-white text-base md:text-2xl font-extrabold">
                                    {{ item?.name }}
                                </div>
                                <div class="text-sm md:text-4.25 text-white">
                                    {{ item?.mail }}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <div class="container z-2 relative hidden sm:block">
            <div class="flex justify-center">
                <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank" class="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    data: Object,
    linkButton: String,
});

const { width } = useWindowSize();

const centeredSlide = ref(true);
onMounted(() => {
    centeredSlide.value = width.value > 768;
});
watch(
    () => width.value,
    () => {
        centeredSlide.value = width.value > 768;
    }
);

const { t } = useI18n();
</script>
<style scoped>
.swiper-slide-active {
    opacity: 100%;
}

.swiper-slide-active .next-swiper-testimonials,
.swiper-slide-active .prev-swiper-testimonials {
    pointer-events: all;
}

.swiper-pagination {}

@media (min-width: 768px) {
    .swiper-pagination {
        display: none !important;
    }
}
</style>
