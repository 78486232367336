//home
import home from '~/app/views/home/index'
import page from '~/app/views/page/index'

export default {
    scrollBehavior() {
        return {top: 0};
    },
    routes: (_routes:any) => [
        //home
        {
            name: "home",
            path: "/",
            component: home,
            alias: ["/vi"],
        },
        {
            name: "page",
            path: "/page/:slug",
            component: page,
            alias: ["/vi/trang/:slug"],
        }
    ]
};
