<template>
    <div id="how-it-works" class="section py-10 lg:py-16 bg-primary-200 relative">
        <div class="container">
            <h2 class="text-white text-7.5 lg:text-10 font-sf-pro font-bold !leading-[1.2] mb-9 text-center">
                {{ data?.title }}
            </h2>
        </div>
        <!-- Desktop -->
        <div class="container relative z-2 lg:block hidden">
            <div class="z-2 relative w-full h-full grid grid-cols-1 md:grid-cols-3 gap-6 steps-container">
                <div v-for="(item, index) in data?.list_step"
                    :class="index + 1 === data?.list_step?.length ? '' : 'pr-6'">
                    <div
                        class="text-center rounded-full px-9 py-2.5 bg-gradient-1 text-white text-lg font-bold leading-41px mx-auto w-max">
                        {{ t("STEP") }} {{ index + 1 }}
                    </div>
                </div>

                <div v-for="(item, index) in data?.list_step" class="vertical-line-gradient"
                    :class="index === 0 ? 'px-6' : 'noBorder'">
                    <h3 class="text-gradient-1 text-xl font-extrabold !leading-[1.2] mb-4 lg:mb-6 text-center">
                        {{ item?.title }}
                    </h3>

                    <div class="text-white">
                        <div v-html="item?.content"
                            class="custom-html text-white text-sm !leading-[1.5] lg:!leading-[2.5]"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div class="container relative z-2 lg:hidden block">
            <div class="z-2 relative w-full h-full grid grid-cols-1 md:grid-cols-3 gap-6">
                <div v-for="(item, index) in data?.list_step" class="horizontal-line-gradient"
                    :class="index + 1 === data?.list_step?.length ? 'noBorder' : 'pb-6'">
                    <div
                        class="text-center rounded-full px-9 py-1 bg-gradient-1 text-white text-lg font-bold leading-41px mx-auto w-max mb-6">
                        {{ t("STEP") }} {{ index + 1 }}
                    </div>
                    <div class="text-gradient-1 text-xl font-extrabold !leading-[1.2] mb-4 lg:mb-6 text-center">
                        {{ item?.title }}
                    </div>

                    <div class="text-white">
                        <div v-html="item?.content"
                            class="custom-html text-white text-sm !leading-[1.5] lg:!leading-[2.5]"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-9 hidden sm:block">
            <div class="flex justify-center">
                <app-button :title="t('GET_STARTED')" :link="linkButton" target="_blank" class="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    data: Object,
    linkButton: String,
});

const { width } = useWindowSize();
const isMobile = ref(false);
onMounted(() => {
    isMobile.value = width.value < 1024;
});
watch(
    () => width.value,
    () => {
        isMobile.value = width.value < 1024;
    }
);

const { t } = useI18n();
</script>
<style scoped>
.custom-line-1 {
    width: 100%;
    border: 2px solid #554de9;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 1;
}

.custom-line-1::before {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    width: 30%;
    height: 22px;
    background: #141140;
}

.custom-line-2 {
    margin-top: -2px;
    width: 100%;
    border: 2px solid #554de9;
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    z-index: 2;
}

.custom-line-2::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    border: 2px solid #554de9;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0 0 0 28px;
    top: calc(-100% + 2px);
    left: 0;
}

.custom-line-2::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 26px;
    height: 2px;
    background: #141140;
    z-index: 3;
}

.custom-line-3 {
    margin-top: -2px;
    width: 100%;
    border: 2px solid #554de9;
    border-right-width: 0px;
    border-top-width: 0px;
    border-radius: 30px;
    border-top-right-radius: 0;
    position: relative;
    z-index: 3;
}

.custom-line-3::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 30%;
    height: 50px;
    background: #141140;
}

.custom-line-3::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    border: 2px solid #554de9;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-radius: 32px 0 0 0;
    top: -2px;
    left: -2px;
}

.custom-1 {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    border: 2px solid #554de9;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-radius: 0 28px 0 0;
    top: 0;
    right: 0;
}

.custom-2 {
    content: "";
    position: absolute;
    width: 52%;
    height: 100%;
    border: 2px solid #554de9;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0 0 26px 0;
    top: -2px;
    right: -2px;
}

.horizontal-line-gradient {
    position: relative;
    height: 100%;
}

.horizontal-line-gradient.noBorder::after {
    display: none;
}

.horizontal-line-gradient::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, #4a4aff, #000066);
}

.vertical-line-gradient {
    position: relative;
    height: 100%;
}

.vertical-line-gradient.noBorder::before {
    display: none;
}

.vertical-line-gradient::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: linear-gradient(to bottom, #554DE9, #1A195B);
}
.vertical-line-gradient::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: linear-gradient(to bottom, #554DE9, #1A195B);
}

.steps-container::before {
    content: "";
    position: absolute;
    top: 27px;
    left: 15%;
    right: 15%;
    height: 6px;
    background-color: #4a4aff;
    z-index: -1;
}
</style>
